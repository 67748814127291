<template>

  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <v-icon
            class="mr-4"
            style="cursor: pointer"
            title="voltar"
            @click="pushRouteToView('appUsers')"
          >
            mdi-arrow-left
          </v-icon>
          <v-icon class="mr-2">mdi-cellphone</v-icon>
          App user
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-data-visualization">
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-account</v-icon> Nome:</strong> {{ appUser.name }}
            </v-col>
            
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-card-account-details-outline</v-icon> CPF:</strong> {{ appUser.cpf }}
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-calendar</v-icon> Data de nascimento:</strong>
              {{ appUser.birthdate }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-phone</v-icon> Celular:</strong> {{ appUser.cellPhone }}
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-at</v-icon> Email:</strong> {{ appUser.email }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-map-marker</v-icon> CEP:</strong> {{ appUser.zipCode }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-map-marker</v-icon> Endereço:</strong> {{ appUser.street }}, {{ appUser.number }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-map-marker</v-icon> Complemento:</strong> {{ appUser.addressComplement }}
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-map-marker</v-icon> Bairro:</strong> {{ appUser.district }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-map-marker</v-icon> Cidade:</strong> {{ appUser.city }}
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-map-marker</v-icon> Estado:</strong> {{ appUser.state }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-currency-usd</v-icon> Saldo Lyndus Faz Bem:</strong> {{ appUser.fazBem | money }}
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong><v-icon>mdi-currency-usd</v-icon> Saldo Lyndus Box:</strong> {{ appUser.lyndusBox | money }}
            </v-col>

          </v-row>
          <v-row>
            <v-col xl="12" lg="12" md="12" sm="12">
              <h4 class="mb-0">Conceder Bônus Lyndus Box</h4>
            </v-col>
            </v-row>
              <v-row>
            <v-col xl="3" lg="3" md="3" sm="3">
             <!-- <v-text-field
                v-model.number="balance"
                type="number"
                label="Valor"
                placeholder="Valor do Bônus Lyndus Box"
                autocomplete="Informe o valor do bônus"
                outlined
              />-->
               
               <number-input
                    outlined
                    v-model="balance"
                    :rules="textRules"
                    label="Valor"
                    placeholder="Valor do Bônus Lyndus Box"
                    autocomplete="Informe o valor do bônus"
                    :options="optionsValue"
                    dense
                    append-icon="mdi-currency-usd"
                  >
                  </number-input>
              
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="6">
              <v-btn color="success" class="mt-3" @click="newBonus()">
                <v-icon left>
                  mdi-content-save
                </v-icon>
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import NumberInput from "@/components/NumberInput";
import {APPUSER_RESET} from "@/store/mutations/appuser.type";
import {APPUSER_GET, UPDATE_BONUS} from "@/store/actions/appuser.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";

export default {
  name: "AppUser",

components: {NumberInput},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      await store.commit(APPUSER_RESET),
      await store.dispatch(APPUSER_GET, to.params.id)
    ]).then(() => {
      next();
    });
  },

  data: () => ({
    balance: null,
    selected: []
  }),

  methods: {
    pushRouteToView(route) {
      this.$router.push({name: route});
    },
    async newBonus() {
      let balance = Number.parseFloat(this.balance);
      if (!isNaN(balance)) {
        this.$store.commit(CHANGE_PROGRESS, true);
        await this.$store
          .dispatch(UPDATE_BONUS, this.balance)
          .then(async () => {

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Adicionado com sucesso.";

            await this.$store.dispatch(APPUSER_GET, this.appUser.id);
            this.$store.commit(CHANGE_PROGRESS, false);
            this.balance = null;
          })
          .catch(res => {

            this.$store.commit(CHANGE_PROGRESS, false);
            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao adicionar Bônus.";

            if (typeof res.data.message !== "undefined") {
              this.snackbar.text = `Erro ao adicionar Bônus: ${res.data.message}`;
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Valor inválido.";
      }
    }
  },

  computed: {
    ...mapGetters(["appUser", "snackbar"])
  }
};
</script>
